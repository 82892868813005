
import { defineComponent } from "vue";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonItem, IonList, IonText } from "@ionic/vue";
import { caretForwardOutline } from "ionicons/icons"

export default defineComponent({
    name: "CategoryCard",
    components: {
        IonCardHeader,
        IonCardTitle,
        IonCardContent,
        IonList,
        IonItem,
        IonCard,
        IonText,
        IonIcon,
    },
    props: {
        entries: Array,
        title: String,
        image: String,
        fontSize: String,
        fontSizeTitle: String,
    },
    setup() {

        // const imageStyle = computed(() => {
        //     return 'background-image: url('+ props.image +')'
        // })
        //
        // return {
        //     imageStyle,
        // }

        return {
            caretForwardOutline,
        }
    }
});
