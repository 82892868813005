
import AudioPlayer from '@liripeng/vue-audio-player'
import { computed, defineComponent, PropType, ref } from "vue";
import { Audio } from "@/types/frontendTypes";
import { getImageUrl } from "@/utils/FileUtils";

export default defineComponent({
    name: "AudioPlayerComp",
    components: {
        AudioPlayer,
    },
    props: {
        audioList: {
            type: Array as PropType<Audio[]>,
            required: true,
        },
    },
    emits: ['changed-audio'],
    setup(props, { emit }) {
        const audioPlayer = ref()

        const controlAudio = (before: () => void) => {
            before()
            emit('changed-audio', audioPlayer.value.currentPlayIndex)
        }

        const audioFiles = computed(() => {
            return props.audioList.map(elm =>
                getImageUrl(elm.file)
            )
        })

        return {
            controlAudio,
            audioPlayer,
            audioFiles
        }
    }
})

