
import { defineComponent } from "vue";

export default defineComponent({
    name: "CustomFooter",

    props: {
        entries: Array,
        title: String,
        isHome: {
            type: Boolean
        }
    }
});
