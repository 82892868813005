import Axios, { AxiosInstance } from "axios";

export const API_URL = ENV.API_URL;

export function useApi() {
    const api: AxiosInstance = Axios.create({
        baseURL: API_URL,
    });

    return {
        api
    }
}