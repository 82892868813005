
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import CategoryCard from "@/components/CategoryCard.vue";
import CustomFooter from "@/components/CustomFooter.vue";
import CustomHeader from "@/components/CustomHeader.vue";
import { useStore } from "@/stores/main";
import { storeToRefs } from "pinia";

export default defineComponent({
    name: 'HomePage',
    components: {
        CustomHeader,
        CategoryCard,
        IonContent,
        IonPage,
        CustomFooter,
    },
    setup() {

        const fontSizeTitle = ref<string>('font-size-title-standard')

        const store = useStore()

        const { plantList, animalList, font } = storeToRefs(store)

        onMounted(() => store.loadContentList())

        return {
            fontSizeTitle,
            plantList,
            animalList,
            font
        }
    }
});
