import { defineStore } from "pinia";
import { Content, Entry } from "@/types/frontendTypes";
import { useApi } from "@/composables/useApi";

interface MainStore {
    font: {
        isFontChanged: boolean;
        fontSize: string;
        fontSizeTitle: string
    };
    contentDetailList: {
        [key: string]: Content
    };
    contentList: {
        category: string;
        entries: Entry[];
    }[];
}

const { api } = useApi()

export const useStore = defineStore('main', {
        state: (): MainStore => {
            return {
                font: {
                    fontSize: 'font-size-standard',
                    fontSizeTitle: 'font-size-title-standard',
                    isFontChanged: false,
                },
                contentList: [],
                contentDetailList: {} ,
            }
        },
        actions: {
            async loadContentList() {
                const response = await api.get('content/list').catch(() => { return undefined })
                if(response?.data) this.contentList = response.data
            },
            async loadContentDetails(id: string) {
                const response = await api.get(`content/detail/${id}`).catch(() => { return undefined })
                if(response?.data) this.contentDetailList[id] = response.data
            },
            changeFontSize() {
                this.font.fontSize = this.font.fontSize === 'font-size-standard' ? 'font-size-bigger' : 'font-size-standard'
                this.font.fontSizeTitle = this.font.fontSizeTitle === 'font-size-title-standard' ? 'font-size-title-bigger' : 'font-size-title-standard'
            }
        },
        getters: {
            plantList(state) {
                return state.contentList.find((element) => element.category === "flora")?.entries
            },
            animalList(state) {
                return state.contentList.find((element) => element.category === "fauna")?.entries
            },
            contentDetails(state) {
                return state.contentDetailList
            }
        }
    }
)