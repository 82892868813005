import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_header = _resolveComponent("custom-header")!
  const _component_category_card = _resolveComponent("category-card")!
  const _component_custom_footer = _resolveComponent("custom-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_custom_header),
          _createVNode(_component_category_card, {
            class: "category-card",
            "font-size-title": _ctx.font.fontSizeTitle,
            "font-size": _ctx.font.fontSize,
            entries: _ctx.animalList,
            image: "animals",
            title: "Tierwelt"
          }, null, 8, ["font-size-title", "font-size", "entries"]),
          _createVNode(_component_category_card, {
            class: "category-card",
            "font-size-title": _ctx.font.fontSizeTitle,
            "font-size": _ctx.font.fontSize,
            entries: _ctx.plantList,
            image: "plants",
            title: "Pflanzenwelt"
          }, null, 8, ["font-size-title", "font-size", "entries"]),
          _createVNode(_component_custom_footer, { "is-home": "" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}